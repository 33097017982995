import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useCustomTheme } from '../../../../../app/hooks/useCustomTheme';

export const AnnouncementsDropdown = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
  const READ_URL = `${BACKEND_URL}/announcement/shop/read`;
  const history = useHistory();
  const { authToken, shop, user } = useSelector(state => state.auth);
  const shopUserId = user?.shopUserId;

  const [announcements, setAnnouncements] = useState([]);
  const [filter, setfilter] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!user) return;
    fetch();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch();
    };
    fetchData();
  }, []);

  const fetch = async () => {
    const ann = await axios.get(`${BACKEND_URL}/announcement/`, {
      headers: { Authorization: `Bearer ${authToken}` },
      params: { shopUserId: shopUserId, type: 'inbox', isMailBox: 'true' }
    });
    const { announcements, countRead } = ann.data;
    setAnnouncements(announcements);

    return setfilter(announcements), setCount(countRead);
  };

  const shopReaded = async id => {
    const announcement = announcements.find(ann => ann._id === id);
    if (!announcement) {
      return;
    }
    if (announcement.readed.includes(shopUserId)) {
      return;
    }
    try {
      await axios.put(
        `${READ_URL}/${id}`,
        { shopUserId },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      await fetch();
    } catch (error) {
      console.log(error);
    }
  };

  const customTheme = useCustomTheme();
  const style = customTheme?.navbar;

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faEnvelope} inverse size="2x" style={{ color: style.icColor }} />
            {count > 0 && (
              <span
                className="fa-layers-counter"
                style={{ background: 'Tomato', fontSize: '42px', marginRight: -20, marginTop: -10 }}
              >
                {count}
              </span>
            )}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
        style={{ width: '300px' }}
      >
        <ul className="navi navi-hover py-4 ">
          {filter.map(ann => (
            <li key={ann._id} className={clsx('navi-item', {})}>
              <a
                onClick={() => {
                  history.push(`/shop/${shop.seqId}/announcement/${ann._id}/detail`);
                  shopReaded(ann._id);
                }}
                className="navi-link"
              >
                <span className="symbol symbol-200 mr-3">
                  <img src={`${ann.image[0]}`} alt={ann.title} />
                </span>
                <span className="navi-text">
                  {ann.title} <br />
                </span>
              </a>
            </li>
          ))}
          <li className={clsx('navi-item', {})}>
            <a onClick={() => history.push(`/shop/${shop.seqId}/announcements`)} className="navi-link">
              <span className="symbol symbol-20 mr-3"> </span>
              <span
                className="navi-text"
                style={{
                  textAlign: 'center'
                }}
              >
                {'show all'}
              </span>
            </a>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const NotificationIcon = styled('div')(() => ({
  backgroundColor: 'red',
  padding: 4,
  width: 15,
  height: 15,
  borderRadius: 50,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  color: 'white',
  margin: '4px 10px 0 0'
}));
